$(document).on("click", ".get-featured-products", function(event) {
    event.preventDefault();
    getFeaturedProducts();
});

function getFeaturedProducts(done = function() {}) {
    ajax(
        "get-featured-products",
        {
            data: {
                skip: $("#skip-value").val(),
                ids: $("#current-ids").val()
            }
        },
        function(response) {
            if (response.status === "success") {
                let productsHTML = response.data.html;
                let featuredProducts = document.getElementById(
                    "featured-products-home"
                );
                let featuredProductsHTML = featuredProducts.innerHTML;
                featuredProducts.innerHTML =
                    featuredProductsHTML + productsHTML;

                $("#skip-value").attr(
                    "value",
                    parseInt($("#skip-value").val()) + response.data.count
                );

                let idsAsString = $("#current-ids").val();
                idsAsString = idsAsString.slice(1, -1);

                idsAsString = idsAsString.split(",");

                let ids = idsAsString.map(function(x) {
                    return parseInt(x);
                });

                if (response.data.ids) {
                    ids = ids.concat(response.data.ids);
                }

                $("#current-ids").attr("value", "[" + ids + "]");

                if (
                    parseInt($("#total-value").val()) -
                        parseInt($("#skip-value").val()) <=
                    0
                ) {
                    $(".get-featured-products").hide();
                }

                done();
            } else {
                console.error(response);
                alert(response.message);
            }
        },
        function(error) {
            console.error(error);
            alert(response.message);
        }
    );
}
