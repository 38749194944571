require("trumbowyg/plugins/resizimg/resizable-resolveconflict");
require("jquery-resizable-dom");
require("trumbowyg");
require("trumbowyg/plugins/upload/trumbowyg.upload");
require("trumbowyg/plugins/pasteimage/trumbowyg.pasteimage");
require("trumbowyg/plugins/base64/trumbowyg.base64");
require("trumbowyg/plugins/colors/trumbowyg.colors");
require("trumbowyg/plugins/resizimg/trumbowyg.resizimg");
require("trumbowyg/dist/langs/hu");

var editors;

jQuery.trumbowyg.langs.hu.reset = "Mégse";

window.makeEditor = function(objects) {
    editors = objects.trumbowyg({
        removeformatPasted: true,
        // If the APP_URL is not the top level domain, make an images folder with the copied icons.svg in the root directory of the web server
        svgPath: "/public/images/icons.svg",
        lang: "hu",
        btnsDef: {
            base64: {
                ico: "insertImage"
            }
        },
        btns: [
            ["viewHTML"],
            ["foreColor", "backColor"],
            ["formatting"],
            ["strong", "em", "del"],
            ["superscript", "subscript"],
            ["link"],
            ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
            ["unorderedList", "orderedList"],
            ["horizontalRule"],
            ["removeformat"],
            ["fullscreen"]
        ],
        plugins: {
            colors: {
                colorList: ["F39323", "68C2C3", "ec6098", "000000", "ffffff"]
            },
            resizimg: {
                minSize: 64,
                step: 16
            }
        }
    });
};

window.clearEditors = function() {
    if (editors) {
        editors.trumbowyg("destroy");
    }
};
