
$(function(){

    var i = 0;
    var counter;
    $(".change-slide-text").keyup(function(){
        const input = $(this)

        clearInterval(counter); i=0;
        counter = setInterval(function(){
            i++;
            console.log(i);
            if(i > 5){
                clearInterval(counter); i=0;
                console.log("vége");
                console.log("küldés...");
                $.ajax({
                    type: "GET",
                    url: input.data("url"),
                    data: {"slider_text":input.val()},
                    dataType: "json",
                    success: function (response) {
                        console.log(response);
                        if(response.status == "success"){
                            input.removeClass("text-danger");
                            input.addClass("text-success");
                            setTimeout(function(){
                                input.removeClass("text-success");
                            },500);
                        } else {
                            input.addClass("text-danger");
                        }
                    },
                    error: function(e){
                        console.log(e);
                        input.addClass("text-danger");
                    }
                });
            }
        },100);




    });

})
