
$(function(){
	var isGridDisplay = $("#grids").hasClass('active');
	$(".grid_switch").click(function(){
		$("#grids").toggleClass("active");
		isGridDisplay = $("#grids").hasClass('active');
		$.ajax({
			type: "GET",
			url: "grid",
			data: {isGridDisplay : isGridDisplay},
			dataType: "json",
			success: function (response) {
				console.log(response);
			}
		});
	});
})
