$(function(){

    var calculator = [];
    $(".calculator").on("click", function(){
        const wrapper = $(this).parents(".calculator-wrapper");
        const label = $(".label", wrapper).html();
        const value = $(".value", wrapper);
        const name = $(".value", wrapper).data("name");
        const price = parseInt($(".value", wrapper).data("price"));
        let number = parseInt(value.html());
        const result = $(".result", wrapper);
        const sumWrapper = $(".calculator-sum");
        
        if($(this).hasClass("subtraction") && number > 0){
            number--;
        }
        if($(this).hasClass("add")){
            number++;
        }
        
        value.html(number);

        result.attr("data-value", number * price);
        result.html(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', minimumFractionDigits: 0 }).format(number * price));

        let sumResult = 0;
        $.each($(".calculator.result"), function(index, value){
            const num = parseInt($(value).attr("data-value"))
            if(!isNaN(num)){
                sumResult = sumResult + num;
            }
        })

        sumAll = new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', minimumFractionDigits: 0 }).format(sumResult);

        sumWrapper.html(sumAll);

        calculator[label] = {
            "name" : name,
            "label" : label,
            "length" : number,
            "price" : price,
        };

        let html = "";

        for (const [key, value] of Object.entries(calculator)) {
            if(value.length > 0){
                html += 
                    `<div>`
                        + value.label + 
                        `<strong>` + value.length + `</strong>
                        <input type="hidden" name="` + value.name + `" value="` + value.length + `">
                    </div>`;
            }
        }
        $(".service-list").html(html);
        $(".modal-sum").html(sumAll);
    });



});