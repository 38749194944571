import Magnifier from 'magnify';

var magnifier;
window.magnifier = function(){
    if(magnifier){
        magnifier.destroy();
    }
    if($("#magnify").length > 0){
        magnifier = $("#magnify").magnify();
    }
    console.log("magnifier betöltve");
};

window.alert = function alert(message){
    const modal = $('#alert');
    $(".modal-body", modal).html(message);
    modal.modal('show');
};

$.fn.findSelf = function(selector) {
    let result = this.find(selector);
    this.each(function() {
        if ($(this).is(selector)) {
            result.add($(this));
        }
    });
    return result;
};

$(function(){
    const alertSuccess = $(".alerts .alert-success");
    const alertDanger = $(".alerts .alert-danger");
    const alertInfo = $(".alerts .alert-info");
    let alert_messages = "";
    let isAlert = false;

    if(alertSuccess.length > 0){
        isAlert = true;
        $.each(alertSuccess, function(index, alert){
            alert_messages += '<p>' + $(alert).html() + '</p>';
        });
    }
    if(alertDanger.length > 0){
        isAlert = true;
        $.each(alertDanger, function(index, alert){
            alert_messages += '<p>' + $(alert).html() + '</p>';
        });
    }if(alertInfo.length > 0){
        isAlert = true;
        $.each(alertInfo, function(index, alert){
            alert_messages += '<p>' + $(alert).html() + '</p>';
        });
    }
    if(isAlert){
        alert(alert_messages);
    }
});
