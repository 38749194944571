$(function() {
    $(".show-phone-number").click(function() {
        let button = $(this);

        $(".tel")
            .attr("href", "tel:" + "+36301252434")
            .text("+36 30 125 2434")
            .removeClass("d-none");
        button.addClass("d-none");

        //This gets the phone number of the user, but they wish to use global phone number
        // ajax("product-request-phone", {}, function (response) {
        //     if(response.status === "success") {
        //         $(".tel")
        //             .attr("href", "tel://" + response.phone_number)
        //             .text(response.phone_number)
        //             .removeClass("d-none");
        //         button.addClass("d-none");
        //     }
        // }, function (error) {
        //     alert("Hiba történt a telefonszám lekérése közben.");
        // });
    });

    $(".show-email").click(function() {
        let button = $(this);

        $(".email")
            .attr("href", "mailto:" + "info@artotek.hu")
            .text("info@artotek.hu")
            .removeClass("d-none");
        button.addClass("d-none");

        //This gets the email of the user, but they wish to use global email
        // ajax(
        //     "product-request-email",
        //     {},
        //     function(response) {
        //         if (response.status === "success") {
        //             $(".email")
        //                 .attr("href", "mailto://" + response.email)
        //                 .text(response.email)
        //                 .removeClass("d-none");
        //             button.addClass("d-none");
        //         }
        //     },
        //     function(error) {
        //         alert("Hiba történt az email cím lekérése közben.");
        //     }
        // );
    });

    $(".slide-button").click(function() {
        $(this)
            .parents(".left-slide-section")
            .toggleClass("active");
    });

    $(".product-modal form").submit(function(event) {
        event.preventDefault();

        $(".modal-body .message", this).hide();
        const form = $(this);
        modalBody = $(".modal-body .message-sended", this);
        preloader = $(".modal-body .preloader", this);
        modalFooter = $(".modal-footer", this);
        modalBody.show();

        $(".close_buttons", modalFooter).show();
        $(".send_buttons", modalFooter).hide();

        modalBody.hide();
        preloader.show();
        sendMessage(this, function(div) {
            modalBody.html(div);
            preloader.fadeOut(200, function() {
                modalBody.fadeIn();
            });
            form.trigger("reset");
        });
        return false;
    });

    $(".product-modal").on("show.bs.modal", function(e) {
        const modalFooter = $(".modal-footer", this);
        $(".close_buttons", modalFooter).hide();
        $(".send_buttons", modalFooter).show();
    });
    $(".product-modal").on("hidden.bs.modal", function(e) {
        const modalFooter = $(".modal-footer", this);
        $(".close_buttons", modalFooter).show();
        $(".send_buttons", modalFooter).hide();
        $(".message", this).show();
        $(".message-sended", this).hide();
    });

    //Új szalag hozzáadása termékszerkesztés közben
    var ribbonCounter = parseFloat($(".ribbon-count").data("count"));
    $(document).on("click", ".add-ribbon-for-product", function() {
        let container = $(".ribbon-container.ribbon-edit");
        let counter =
            ribbonCounter -
            $(".ribbon-container.ribbon-edit .ribbon").length -
            1;
        if (counter >= 0) {
            if (counter != Infinity) {
                $(".ribbon-count").text(counter);
            }
            let ribbon = $($("#ribbon-template").html());
            let ribbonClone = ribbon.clone();
            $("input", ribbonClone).val("");
            let ribbonCount = container.children(".ribbon").length + 1;
            ribbonClone.attr("id", "ribbon-" + (ribbonCount + 1));
            container.append(ribbonClone);
        } else {
            alert("Nincs több szalaghirdetésed!");
        }
    });

    //kiemelések számolása
    $('[name="featured[home]"]').change(function() {
        let $element = $(".featured-home-counter");
        let counter = parseFloat($element.data("count"));
        if ($(this).is(":checked")) {
            if (counter === 0) {
                $(this).prop("checked", false);
                alert("Nincs több főoldali kiemelési lehetőséged!");
            } else {
                counter--;
                $element.data("count", counter);
            }
        } else {
            counter++;
            $element.data("count", counter);
        }
        if (counter != Infinity) {
            $element.text(counter);
        }
    });

    $('[name="featured[list]"]').change(function() {
        let $element = $(".featured-list-counter");
        let counter = parseFloat($element.data("count"));
        if ($(this).is(":checked")) {
            if (counter === 0) {
                $(this).prop("checked", false);
                alert("Nincs több főoldali kiemelési lehetőséged!");
            } else {
                counter--;
                $element.data("count", counter);
            }
        } else {
            counter++;
            $element.data("count", counter);
        }
        if (counter != Infinity) {
            $element.text(counter);
        }
    });

    //képléptetés
    var img = $(".nowrap_img_container .img_slide");
    var padding = 30;
    var img_cont_width = img.length * (img.width() + padding);
    var slide_stap = img.width() + padding;
    var n = 0;
    $(".prev_img").click(function() {
        if (n > 0) {
            n = n - slide_stap;
            $(".nowrap_img_container").animate({ scrollLeft: n }, 200);
            const current_img_slide = $(".img_slide.active");
            current_img_slide
                .removeClass("active")
                .prev(".img_slide")
                .addClass("active");
            const url = $(".img_slide.active img").attr("src");
            $(".main_img img").attr("src", url);
            $(".main_img img").attr("data-magnify-src", url);
            magnifier();
        }
    });
    $(".next_img").click(function() {
        if (n < img_cont_width - slide_stap) {
            n = n + slide_stap;
            $(".nowrap_img_container").animate({ scrollLeft: n }, 200);
            const current_img_slide = $(".img_slide.active");
            current_img_slide
                .removeClass("active")
                .next(".img_slide")
                .addClass("active");
            const url = $(".img_slide.active img").attr("src");
            $(".main_img img").attr("src", url);
            $(".main_img img").attr("data-magnify-src", url);
            magnifier();
        }
    });
    $(".img_slide").click(function() {
        n = slide_stap * $(this).index(".img_slide");
        $(".nowrap_img_container").animate({ scrollLeft: n }, 200);

        $(".img_slide.active").removeClass("active");
        $(this).addClass("active");
        const url = $("img", this).attr("src");
        $(".main_img img").attr("src", url);
        $(".main_img img").attr("data-magnify-src", url);
        magnifier();
    });
});

function sendMessage(form, onSuccess, onError) {
    let formData = new FormData(form);

    ajax(
        form.dataset.action,
        {
            data: formData
        },
        function(response) {
            if (response.status === "success") {
                let message = "Sikeres e-mail küldés!";
                let icon = "fa-check text-secondary";

                onSuccess(
                    `<div class="text-center">
                <i class="fa text-xl my-3 ` +
                        icon +
                        `"></i>
                <p>` +
                        message +
                        `</p>
            </div>`
                );
            } else {
                console.error(response);
                let icon = "fa-times text-danger";
                let message = "Sikertelen e-mail küldés!";

                onSuccess(
                    `<div class="text-center">
                <i class="fa text-xl my-3 ` +
                        icon +
                        `"></i>
                <p>` +
                        message +
                        `</p>
            </div>`
                );
            }
        },
        function(error) {
            console.error(error);
            let icon = "fa-times text-danger";
            let message = "Sikertelen e-mail küldés!";

            onSuccess(
                `<div class="text-center">
                <i class="fa text-xl my-3 ` +
                    icon +
                    `"></i>
                <p>` +
                    message +
                    `</p>
            </div>`
            );
        }
    );
}
