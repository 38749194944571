require('cookie-notice');
new cookieNoticeJS({
    'messageLocales': {
        'hu': 'Az Artotek, szolgáltatásainak fejlesztése, fenntartása, és a felhasználói élmény fokozása érdekében sütiket (cookie-kat) használ.<br>A honlapunkon való böngészéssel Ön hozzájárul a sütik használatához.<br><br>'
    },
    'buttonLocales': {
        'hu': 'Rendben'
    },
    'cookieNoticePosition': 'bottom',
    'learnMoreLinkEnabled': false,
    'learnMoreLinkHref': '/learn/more/index.html',
    'learnMoreLinkText':{
        'hu':'Süti szabályzat'
    },
    'fontSize': '14px',
    'noticeBgColor': '#000000cd',
})
