require("inputmask");

window.addEventListener("fileUploadWithPreview:imagesAdded", function(e) {
    let errors = [];
    const limit = 3;
    let files = e.detail.cachedFileArray;

    $.each(files, function(index, file) {
        console.log(e.detail);
        const mimeTypes = ["image/jpeg", "image/png"];
        const fileSize = file.size / 1024 / 1024;
        let type = file.type;
        let uploadId = e.detail.uploadId;

        // calculateFileSizes(uploadId, fileSize);
        if (fileSize > limit) {
            errors.push("A feltöltött fájl túl nagy: " + file.name + "<br>");
            // calculateFileSizes(uploadId, 0);
        }
        if (!mimeTypes.includes(type)) {
            errors.push(
                "A feltöltött fájl típusa nem megfelelő: " + file.name + "<br>"
            );
            //calculateFileSizes(uploadId, 0);
        }
    });

    if (errors.length > 0) {
        let result = "";
        $.each(errors, function(index, error) {
            result += error;
        });
        alert(result);
        setTimeout(function() {
            customFileUploaders[e.detail.uploadId].clearPreviewPanel();
        }, 100);
        setTimeout(function() {
            customFileUploaders[e.detail.uploadId].clearPreviewPanel();
        }, 1000);
    }

    function calculateFileSizes(uploadId, fileSize) {
        console.log(uploadId);
        const maxFileSize = 2;
        const maxPostSize = 8;
        let $fileSizeElement = $("#" + uploadId + "-size");
        if (!$fileSizeElement.length) {
            return;
        }

        fileSize = fileSize.toPrecision(2);
        $fileSizeElement.text(fileSize);

        if (fileSize > maxFileSize) {
            $fileSizeElement.addClass("text-danger");
            $fileSizeElement.next().addClass("text-danger");
        } else {
            $fileSizeElement.removeClass("text-danger");
            $fileSizeElement.next().removeClass("text-danger");
        }

        let sum = 0;
        $(".fileupload-image-size").each(function(index, el) {
            sum += parseFloat($(el).text(), 10);
        });
        sum = sum.toPrecision(2);

        let $postSizeElement = $("#fileupload-image-size-sum");
        $postSizeElement.text(sum);

        if (sum > maxPostSize) {
            $postSizeElement.addClass("text-danger");
            $postSizeElement.next().addClass("text-danger");
        } else {
            $postSizeElement.removeClass("text-danger");
            $postSizeElement.next().removeClass("text-danger");
        }
    }
});

//Example starter JavaScript for disabling form submissions if there are invalid fields
(function() {
    "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function(form) {
        form.addEventListener(
            "submit",
            function(event) {
                console.log($("input[type=file].required", form));

                let isImagesValid = true;

                $("input[type=file].required", form).each(function(
                    index,
                    input
                ) {
                    if (!$(input).val()) {
                        isImagesValid = false;

                        $(input)
                            .parent()
                            .addClass("is-invalid");
                    }
                });

                let taxnumberElement = $("input[type=tax_number]");
                let taxnumberInvalid = false;

                if (taxnumberElement.prop("required") == true) {
                    let taxnumber = taxnumberElement.val();

                    taxnumber = taxnumber.replace(/-/g, "");
                    taxnumber = taxnumber.replace(/_/g, "");
                    console.log(taxnumber);
                    if (taxnumber.length < 11) {
                        taxnumberInvalid = true; //invalid
                        taxnumberElement.addClass(
                            "is-invalid form-control:invalid"
                        );
                    } else {
                        taxnumberElement.removeClass(
                            "is-invalid form-control:invalid"
                        );
                        taxnumberInvalid = false;
                    }
                }

                if (
                    !(
                        form.checkValidity() &&
                        isImagesValid &&
                        !taxnumberInvalid
                    )
                ) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                $(".form-control:invalid")
                    .first()
                    .parents(".collapse")
                    .collapse("show");

                $(".is-invalid")
                    .first()
                    .parents(".collapse")
                    .collapse("show");

                form.classList.add("was-validated");
            },
            false
        );
    });
})();

//zipcode
var zipcode = new Inputmask("9999");
$.each($('input[type="zipcode"]'), function(index, input) {
    zipcode.mask(input);
});

//taxnumber
var taxnumber = new Inputmask("99999999-9-99");
$.each($('input[type="tax_number"]'), function(index, input) {
    taxnumber.mask(input);
});

//phone
var phone = new Inputmask("+36-9[9]/999-999[9]");
$.each($('input[type="phone"]'), function(index, input) {
    phone.mask(input);
});
