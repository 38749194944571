// TODO THIS CHANGE THIS
window.actions = {};

$(function() {
    $("#actions form.action").each(function(index, element) {
        let actionForm = $(element);

        let actionUrl = actionForm.attr("action");
        let actionMethod = actionForm.attr("method").toUpperCase();

        let classes = actionForm.attr("class").split(/\s+/);
        // console.log(classes);
        let actionName;
        for (let className of classes) {
            let result = className.match(/^(.*)-action$/);
            if (result && result.length > 1) {
                actionName = result[1];
                actions[actionName] = {
                    method: actionMethod,
                    url: actionUrl
                };
                break;
            }
        }
        // console.log(actions);
    });
});

function ajax(
    actionName,
    params = {},
    onSuccess = function() {},
    onError = function() {}
) {
    if (window.actions.hasOwnProperty(actionName)) {
        let action = window.actions[actionName];

        let routeParams = params.route ?? {};

        let url = action.url;
        for (let [name, value] of Object.entries(routeParams)) {
            url = url.replace("##" + name + "##", value);
        }

        let data = params.data ?? {};

        let headers = params.headers ?? {};

        let method;

        if (["GET", "POST", "DELETE"].includes(action.method.toString().toUpperCase())) {
            method = action.method;
        } else {
            method = "POST";
            if (data instanceof FormData) {
                data.append("_method", action.method);
            } else {
                data["_method"] = action.method;
            }
        }

        axios({
            method: method,
            url: url,
            data: data,
            headers: headers,
            validateStatus: function(status) {
                // We have to allow more status codes so its treated as error
                return (
                    (status >= 200 && status < 300) ||
                    status === 401 ||
                    status === 419 ||
                    status === 422
                );
            }
        })
            .then(function(response) {
                // If the user is logged out we reload the page so he/she can login
                if (response.status === 401 || response.status === 419) {
                    alert(response.data.message);
                    window.location.reload();
                } else if (response.status === 422) {
                    alert(
                        "A megadott adat érvénytelen. Túl nagy méretű vagy nem megfelelő formátumú képet próbáltál meg feltölteni. Csak 2 MB alatti, jpg vagy png formátumú képet lehet feltölteni."
                    );
                } else if (response.status === 413) {
                    alert(
                        "A megadott adat érvénytelen. Túl nagy méretű file-t próbáltál meg feltölteni. Csak 2 MB alatti file-t lehet feltölteni."
                    );
                } else {
                    onSuccess(response.data);
                }
            })
            .catch(function(error) {
                onError(error);
            });
    } else {
        throw new Error("Action named " + actionName + " does not exist.");
    }
}

module.exports = {
    //actions : actions,
    ajax: ajax
};
