$(function(){


    $(".radio-container input[type='radio']").on("change", function(){
        const input = $(this);
        const container = input.parents(".radio-container");
        const priceBasic = input.data("basic-price");
        const priceStandard = input.data("standard-price");
        const pricePremium = input.data("premium-price");
        const period = input.val();
        const discount = input.data("discount");
        let selector = "";

        if(container.hasClass("gallery")){
            selector = "gallery";
        }
        if(container.hasClass("artist")){
            selector = "artist";
        }
        if(container.hasClass("dealer")){
            selector = "dealer";
        }

        //formnak átadjuk az információt
        if(input.is(':checked')){
            $('.' + selector + '-form-container input[name="type"]').val(input.val());
        }

        //kijelzések
        if(period == "semiannual"){
            $('.' + selector + '-price-container .half-year-wrapper').show();
            $('.' + selector + '-price-container .year-wrapper').hide();
            $('.' + selector + '-price-container .discount-wrapper').hide();
        }
        else if(period == "annual"){
            $('.' + selector + '-price-container .half-year-wrapper').hide();
            $('.' + selector + '-price-container .year-wrapper').show();
            $('.' + selector + '-price-container .discount-wrapper').hide();

        } else  {
            $('.' + selector + '-price-container .half-year-wrapper').hide();
            $('.' + selector + '-price-container .year-wrapper').show();
        }
        $('.' + selector + '-price-container .discount').text(discount);


        //hónap
        $('.' + selector + '-price-container .basic .month').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(priceBasic));
        $('.' + selector + '-price-container .standard .month').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(priceStandard));
        $('.' + selector + '-price-container .premium .month').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(pricePremium));

        //év
        $('.' + selector + '-price-container .basic .year').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(priceBasic * 12));
        $('.' + selector + '-price-container .standard .year').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(priceStandard * 12));
        $('.' + selector + '-price-container .premium .year').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(pricePremium * 12));

        //fél év
        $('.' + selector + '-price-container .basic .half-year').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(priceBasic * 6));
        $('.' + selector + '-price-container .standard .half-year').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(priceStandard * 6));
        $('.' + selector + '-price-container .premium .half-year').text(new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits : 0 }).format(pricePremium * 6));
    });

    $(".order_button").on("click", function(e){
        e.preventDefault();
        const form = $(this).parents("form");

        if(confirm("Biztosan csomagot szeretnék váltani?")){
            form.trigger("submit");
        }

    });


})
