$(function(){
    $(".terminal").click(function(){
        $("input", this).focus();
    });


    $(".terminal").submit(function(e){e.preventDefault();});
    $(document).on('keypress',function(e) {
        if(e.which == 13) {
            console.log("terminal submit");

            const url = $(".terminal form").attr('action');
            const input = $('.terminal input[name=cmd]');
            let commandPrompt = $(".command-prompt").text();
            console.log(input);

            $.ajax({
                url:url,
                method:"POST",
                dataType:"json",
                data:{
                    cmd: input.val(),
                    _token: $("input[name=_token]").val()
                }
            })
            .done(function(response) {
                console.log(response);
                $(".output").append('<div class="text-success">' + commandPrompt + input.val() + '</div>');
                $.each(response.output, function (index, value) {
                    $(".output").append('<div class="text-success">' + value + '</div>');
                });
            })
            .fail(function(response) {
                console.log(response);
                $(".output").append('<div class="text-danger">' + response.responseText + '</div>');
            })
            .always(function(){
                input.val("");
            });

        }
    });
})
