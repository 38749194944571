require("./bootstrap");
require("./grid.js");
require("./product.js");
require("./calculator.js");
require("lightgallery");
require("./plans.js");
require("./functions.js");
require("./admin.js");
require("./slider.js");
require("./validate.js");
require("./cookie.js");
require("./contenteditable.js");
require("./editor.js");
require("./terminal.js");
require("./home.js");

import FileUploadWithPreview from "file-upload-with-preview";

$(function() {
    // Hotfix
    $(window).on("resize", function() {
        setLeftSlideSectionTop();
    });
    setLeftSlideSectionTop();

    $(".carousel").carousel({
        interval: 10000
    });

    if ($(".lightgallery").length > 0) {
        $(".lightgallery").lightGallery({
            selector: ".image-gallery"
        });
    }

    magnifier();

    window.customFileUploaders = {};
    $.each($(".custom-file-container"), function(index, fileContainer) {
        const id = $(fileContainer).attr("data-upload-id");
        const ariaLabel = $('input[type="file"]', fileContainer).attr(
            "aria-label"
        );
        customFileUploaders[id] = new FileUploadWithPreview(id, {
            text: {
                chooseFile: ariaLabel,
                browse: "Válassz",
                selectedCount: "fájl kiválasztva"
            },
            maxFileCount: 4,
            images: {
                baseImage: "images/image_upload_placeholder.svg",
                backgroundImage: "none"
            },
            showDeleteButtonOnImages: false
        });
    });

    $(document).on(
        "click",
        ".custom-file-container__image-preview",
        function() {
            $(this)
                .prev(".custom-file-container__custom-file")
                .trigger("click");
        }
    );

    $("#product-watcher-add").click(function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        let formData = new FormData(document.forms["search"]);

        ajax(
            "product-watcher-add",
            {
                data: formData
            },
            function(response) {
                if (response.status === "success") {
                    alert("Hirdetés figyelő sikeresen hozzáadva");
                } else {
                    console.error(response);
                    alert(response.message);
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a hirdetés figyelő hozzáadása közben.");
            }
        );
    });

    $(".product-watcher-delete").click(function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        let productWatcher = $(this).parents(".product-watcher");

        let uuid = productWatcher.data("uuid");

        ajax(
            "product-watcher-delete",
            {
                route: {
                    uuid: uuid
                }
            },
            function(response) {
                if (response.status === "success") {
                    alert("Hirdetés figyelő sikeresen eltávolítva");
                    productWatcher.remove();
                } else {
                    console.error(response);
                    alert(response.message);
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a hirdetés figyelő eltávolítása közben.");
            }
        );
    });

    //editor================================
    // var editors = [];

    //kedvencekhez adás
    $(document).on("click", ".add-favourite", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        const favouriteWrapper = $(this).parents(".product-wrapper");
        const button = $(this);

        addFavourite(favouriteWrapper.attr("data-slug"), function() {
            button.removeClass("add-favourite").addClass("delete-favourite");

            $("i.fa", button)
                .removeClass("fa-star-o")
                .addClass("fa-star");

            $(".title", button).text("Mentve");
        });
        return false;
    });

    //kedvencekből törlés
    $(document).on("click", ".delete-favourite", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        const favouriteWrapper = $(this).parents(".product-wrapper");
        const button = $(this);

        deleteFavourite(favouriteWrapper.attr("data-slug"), function() {
            button.removeClass("delete-favourite").addClass("add-favourite");

            $("i.fa", button)
                .removeClass("fa-star")
                .addClass("fa-star-o");

            $(".title", button).text("Mentés");
        });
    });

    //Termék(hirdetés) publikálása
    $(document).on("click", ".publish-product", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        let id = $(this).data("product-slug");
        if (confirm("Biztosan publikálni szeretné a hirdetését?")) {
            publishProduct(id, function() {
                window.location.reload();
            });
        }
    });

    //Termék(hirdetés) inaktiválása (unpublish)
    $(document).on("click", ".unpublish-product", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();
        let id = $(this).data("product-slug");
        if (confirm("Biztosan inaktiválni szeretné a hirdetését?")) {
            unpublishProduct(id, function() {
                window.location.reload();
            });
        }
    });

    //termék(hirdetés) törlése
    $(document).on("click", ".delete-product", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (
            confirm(
                "Biztosan törölni szeretné a hirdetését? A törlés végleges és nem visszavonható!"
            )
        ) {
            const productWrapper = $(this).parents(".product-wrapper");
            deleteProduct(productWrapper.attr("data-slug"), function() {
                productWrapper.fadeOut(400, function() {
                    productWrapper.remove();
                });
            });
        }
    });

    //Banner törlése (ADMIN)
    $(document).on("click", ".delete-banner", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const bannerWrapper = $(this).parents(".banner-wrapper");
            deleteBanner(bannerWrapper.attr("data-slug"), function() {
                bannerWrapper.fadeOut(400, function() {
                    bannerWrapper.remove();
                });
            });
        }
    });

    //Slider törlése (ADMIN)
    $(document).on("click", ".delete-slider", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const sliderWrapper = $(this).parents(".slider-wrapper");
            deleteSlider(sliderWrapper.attr("data-id"), function() {
                sliderWrapper.fadeOut(400, function() {
                    sliderWrapper.remove();
                });
            });
        }
    });

    //Felhasználó törlése (ADMIN)
    $(document).on("click", ".delete-user", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const userWrapper = $(this).parents(".user-wrapper");
            deleteUser(userWrapper.attr("data-username"), function() {
                userWrapper.fadeOut(400, function() {
                    userWrapper.remove();
                });
            });
        }
    });

    //galéria(felhasználó) törlése
    $(document).on("click", ".delete-gallery", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const galleryWrapper = $(this).parents(".gallery-wrapper");
            deleteGallery(galleryWrapper.attr("data-slug"), function() {
                galleryWrapper.fadeOut(400, function() {
                    galleryWrapper.remove();
                });
            });
        }
    });

    //alkotó törlése
    $(document).on("click", ".delete-artist", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const artistWrapper = $(this).parents(".artist-wrapper");
            deleteArtist(artistWrapper.attr("data-slug"), function() {
                artistWrapper.fadeOut(400, function() {
                    artistWrapper.remove();
                });
            });
        }
    });

    //hír törlése
    $(document).on("click", ".delete-article", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const articleWrapper = $(this).parents(".article-wrapper");
            deleteArticle(articleWrapper.attr("data-slug"), function() {
                articleWrapper.fadeOut(400, function() {
                    articleWrapper.remove();
                });
            });
        }
    });

    //artifact törlése
    $(document).on("click", ".delete-artifact", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (confirm("Biztos törlöd?")) {
            const artifactWrapper = $(this).parents(".artifact-wrapper");
            deleteArtifact(artifactWrapper.attr("data-slug"), function() {
                artifactWrapper.fadeOut(400, function() {
                    artifactWrapper.remove();
                });
            });
        }
    });

    //fájl törlése
    $(document).on("click", ".file-btn.delete", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        const catalogWrapper = $(this).parents(".catalog-wrapper");
        deleteFile(catalogWrapper.attr("data-uuid"), function() {
            catalogWrapper.fadeOut(400, function() {
                $(this).remove();
            });
        });
    });

    //kép törlése
    $(document).on("click", ".image-btn.delete", function(event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        const imageWrapper = $(this).parents(".image-wrapper");
        deleteImage(
            $(this)
                .parents(".loader-image-js")
                .attr("data-uuid"),
            function() {
                imageWrapper.fadeOut(400, function() {
                    $(this).remove();
                });
            }
        );
        return false;
    });

    //fájl feltöltése
    $(document).on("change", '.file-btn.add input[type="file"]', function() {
        const addFileSection = $(this).parents(".add-file-section");
        fileUpload(this, function(fileWrapper) {
            addFileSection.before(fileWrapper);
        });
    });

    //kép feltöltése
    $(document).on("change", '.image-btn.add input[type="file"]', function() {
        const addImageSection = $(this).parents(".add-image-section");
        imageUpload("add", this, function(image) {
            let imageWrapper = $(
                '<div class="col-6 col-md-3 my-2 image-wrapper">' +
                    image +
                    "</div>"
            );
            addImageSection.before(imageWrapper);
            $(document).ready(function() {
                imageWrapper.find(".editable-image").trigger("edit");
            });
        });
    });

    //kép cseréje
    $(document).on("change", '.image-btn.edit input[type="file"]', function() {
        const imageWrapper = $(this)
            .parents(".editable-image")
            .parent(".image-wrapper");
        imageUpload("change", this, function(image) {
            imageWrapper.html(image);
            $(document).ready(function() {
                imageWrapper.find(".editable-image").trigger("edit");
            });
        });
    });

    //Felhasználó értékelése
    $(document).on("change", ".user-rating", function() {
        let value = $(this).val();
        let name = $(this).attr("name");
        let star = $(this)
            .next("label")
            .find("i");

        let formData = new FormData();
        formData.append(name, value);

        star.removeClass("fa-star-o").addClass("fa-star");
        star.parents(".custom-rating")
            .prevAll()
            .find("i")
            .removeClass("fa-star-o")
            .addClass("fa-star");
        star.parents(".custom-rating")
            .nextAll()
            .find("i")
            .removeClass("fa-star")
            .addClass("fa-star-o");

        ajax(
            "rate-user",
            {
                data: formData
            },
            function(response) {
                if (response.status == "success") {
                    let rating = response.rating;
                    let rating_count = response.rating_count;
                    $(".gallery-rating").html(rating);
                    $(".gallery-rating-count").html(rating_count);
                }
                if (response.status == "error") {
                    alert("Hiba történt a felhasználó értékelése közben!");
                }
            }
        );
    });

    //weboldal értékelése
    $(document).on("change", ".page-rating", function(e) {
        e.preventDefault;
        let value = $(this).val();
        let name = $(this).attr("name");
        let star = $(this)
            .next("label")
            .find("i");

        let formData = new FormData();
        formData.append(name, value);

        star.removeClass("fa-star-o").addClass("fa-star");
        star.parents(".custom-rating")
            .prevAll()
            .find("i")
            .removeClass("fa-star-o")
            .addClass("fa-star");
        star.parents(".custom-rating")
            .nextAll()
            .find("i")
            .removeClass("fa-star")
            .addClass("fa-star-o");
    });

    $(document).on("edit", ".editable-image", function() {
        console.log("edit");
        let labelClass = "";
        let image = $(this);
        if (image.hasClass("edit")) {
            labelClass += " edit";
        }
        if (image.hasClass("delete")) {
            labelClass += " delete";
        }

        const editLabel = $(
            '<label class="image-btn' + labelClass + '"></label>'
        );
        const editButton = $('<i class="btn"></i>');
        const id = $(image).attr("name");

        editLabel.append(editButton);

        image.append(editLabel).addClass("editing");

        //A képet nem lehet szerkeszteni, ha törölni igen
        let fileInput;
        if (!image.hasClass("delete")) {
            fileInput = $('<input type="file" class="d-none">');
        } else {
            fileInput = $(
                '<input type="hidden" value="' +
                    $(image).attr("data-uuid") +
                    '" class="d-none">'
            );
        }

        fileInput.attr("name", id).attr("id", id);
        editLabel.append(fileInput).attr("for", id);
    });

    //szerkesztés gomb megnyomásakor
    $(document).on("click", ".edit-content", function() {
        let button = $(this);
        let form = button.parents("form");
        const editableImage = $(".editable-image", form);

        if (button.hasClass("editing")) {
            // console.log("Ha a  mentés gombot nyomod");
            button.removeClass("editing");
            button.val("Szerkesztés");
            form.removeClass("editing");

            $($(".editable-image"), form).removeClass("editing");

            $(".image-btn.delete").remove();
            $(".image-btn.edit").remove();

            $(".add-image-section").hide();
            $(".add-file-section").hide();
            $(".delete-file-section").hide();
            $(".upload-criteria").hide();

            clearEditors();

            $(".editable-text", form).removeAttr("contenteditable");

            save(form);
        } else {
            // console.log("Ha a szerkesztés gombot nyomod");

            //formázások
            button.addClass("editing");
            button.val("Mentés");
            form.addClass("editing");

            $(".add-image-section").show();
            $(".add-file-section").show();
            $(".delete-file-section").show();
            $(".upload-criteria").show();

            //képszerkesztő elemek kiosztása
            editableImage.trigger("edit");

            //szövegszerkesztő elemek kiosztása
            $($(".editable-text"), form).attr("contenteditable", "true");
            if ($(".admin-editor").length > 0) {
                makeEditor($(".admin-editor"));
            }
        }
    });

    // Editor beállítása
    if ($(".new-editor").length > 0) {
        makeEditor($(".new-editor"));
    }

    function save(form) {
        let formData = new FormData(form[0]);
        const action = form.attr("action");

        $.each($(".editable", form), function() {
            formData.append($(this).attr("name"), $(this).html());
        });

        ajax(
            action,
            {
                data: formData
            },
            function(response) {
                if (response.status === "success") {
                } else if (response.status === "message") {
                    alert(response.message);
                } else {
                    console.error(response);
                    alert(response.message);
                }
            },
            function(error) {
                console.error(error);
            }
        );

        for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
        }
    }

    function deleteFile(uuid, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("fájl törlése: ", uuid);
        ajax(
            "file-delete",
            {
                route: {
                    uuid: uuid
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt a fájl törlése közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a fájl törlése közben.");
            }
        );
    }

    function deleteImage(uuid, done = function() {}) {
        //ha a backend szerint sikerült a törlés

        console.log("kép törlése: ", uuid);
        ajax(
            "image-delete",
            {
                route: {
                    uuid: uuid
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt a kép törlése közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a kép törlése közben.");
            }
        );
    }

    function imageUpload(uploadType, input, done = function() {}) {
        console.log("kép feltöltése: ");

        let formData = new FormData();
        let file = input.files[0];
        formData.append("image", file, file.name);

        let action;
        if (uploadType == "add") {
            action = "image-add";
        } else {
            action = "image-set";
        }

        ajax(
            action,
            {
                data: formData
            },
            function(response) {
                if (response.status === "success") {
                    done(response.image);
                } else {
                    console.error(response);
                    let alertMessage = response.message + "<br/>";
                    if (response.errors) {
                        alertMessage += response.errors.join("<br/>");
                    }
                    alert(alertMessage);
                }
            },
            function(error) {
                console.error(error);
                alert(
                    "Hiba történt a kép feltöltése közben. Lehet hogy túl nagy méretű vagy nem megfelelő formátumú képet próbáltál meg feltölteni. Csak 2 MB alatti, jpg vagy png formátumú képet lehet feltölteni."
                );
            }
        );
    }

    function fileUpload(input, done = function() {}) {
        console.log("Fájl feltöltése: ");

        let formData = new FormData();
        let file = input.files[0];
        formData.append("file", file, file.name);

        ajax(
            "file-add",
            {
                data: formData
            },
            function(response) {
                if (response.status === "success") {
                    done(response.file);
                } else {
                    console.error(response);
                    let alertMessage = response.message + "<br/>";
                    if (response.errors) {
                        alertMessage += response.errors.join("<br/>");
                    }
                    alert(alertMessage);
                }
            },
            function(error) {
                console.error(error);
                alert(
                    "Hiba történt a file feltöltése közben. Lehet hogy túl nagy méretű vagy nem megfelelő formátumú filet próbáltál meg feltölteni. Csak 2 MB alatti, pdf formátumú filet lehet feltölteni."
                );
            }
        );
    }

    function deleteArtist(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Alkotó törlése: ", slug);
        ajax(
            "artist-delete",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt az alkotó törlése közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt az alkotó törlése közben.");
            }
        );
    }

    function deleteArticle(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Hír törlése: ", slug);
        ajax(
            "article-delete",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt az alkotó törlése közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt az alkotó törlése közben.");
            }
        );
    }

    function deleteArtifact(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Műtárgy törlése: ", slug);
        ajax(
            "artifact-delete",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                console.log(response);
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt az alkotás törlése közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt az alkotás törlése közben.");
            }
        );
    }

    function deleteGallery(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Galéria törlése: ", slug);
        if (true) {
            done();
        } else {
            alert("Hiba történt az alkotó törlése közben");
        }
    }

    function deleteBanner(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Banner törlése: ", slug);

        if (true) {
            done();
        } else {
            alert("Hiba történt a banner törlése közben");
        }
    }
    function deleteSlider(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Slider törlése: ", slug);
        $.ajax({
            type: "delete",
            url: "slider/" + slug,
            dataType: "json",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
            },
            success: function(res) {
                if (res.status === "success") {
                    done();
                } else {
                    alert("Hiba történt a termék törlése közben.");
                }
            },
            error: function() {
                alert("Hiba történt a Slider törlése közben");
            }
        });
    }

    function deleteUser(userName, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("User törlése: ", userName);
        if (true) {
            done();
        } else {
            alert("Hiba történt a felhasználó törlése közben");
        }
    }

    function deleteProduct(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Termék törlése: ", slug);
        ajax(
            "product-delete",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt a termék törlése közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a termék törlése közben.");
            }
        );
    }

    function publishProduct(slug, done = function() {}) {
        //ha a backend szerint sikerült a publikálés
        console.log("Termék publikálása: ", slug);
        ajax(
            "product-publish",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    alert(response.message);
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a termék publikálása közben.");
            }
        );
    }

    function unpublishProduct(slug, done = function() {}) {
        //ha a backend szerint sikerült az inaktiválás
        console.log("Termék inaktiválása: ", slug);
        ajax(
            "product-unpublish",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert("Hiba történt a termék inaktiválása közben.");
                }
            },
            function(error) {
                console.error(error);
                alert("Hiba történt a termék inaktiválása közben.");
            }
        );
    }

    function deleteFavourite(slug, done = function() {}) {
        //ha a backend szerint sikerült a törlés
        console.log("Kedvencekből törlés: ", slug);
        ajax(
            "product-favourite-delete",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert(response.message);
                }
            },
            function(error) {
                console.error(error);
                alert(response.message);
            }
        );
    }

    function addFavourite(slug, done = function() {}) {
        //ha a backend szerint sikerült a hozzáadás
        console.log("Kedvencekhez: ", slug);
        ajax(
            "product-favourite-add",
            {
                route: {
                    slug: slug
                }
            },
            function(response) {
                if (response.status === "success") {
                    done();
                } else {
                    console.error(response);
                    alert(response.message);
                }
            },
            function(error) {
                console.error(error);
                alert(response.message);
            }
        );
    }

    // Ha van és betöltődött a termék szerkesztése form
    if ($(".product-editor-form").length > 0) {
        let virtualButton = $(
            `<input type="button" class="btn btn-secondary btn-sm edit-content float-right d-none" value="Szerkesztés">`
        );
        $(".product-editor-form").prepend(virtualButton);
        virtualButton.click();
    }
    //=======================================

    // További alkotások profil oldalon
    $(document).on("click", "#show-more-artist-product-cards", function(event) {
        $(".artist-product-card").each(function(index, el) {
            $(el).attr("style", "display:block;");
        });
        $("#show-more-artist-product-cards")
            .parent()
            .parent()
            .css("display", "none");
    });

    $(document).on("click", ".js-delete-user", function(e) {
        // e.preventDefault();
        // let id = $(this).data("user-id");
        // if (confirm("Biztosan törölni szeretnéd a felhasználót?")) {
        //     $("#delete-user-form-" + id).trigger("submit");
        // }
    });
});

function setLeftSlideSectionTop(top) {
    let navbar = $(".navbar").last();
    let navBarTop = navbar.position().top;
    let navBarHeight = navbar.height();
    $(".left-slide-section").css("top", navBarTop + navBarHeight + 10);
}

$(document).on("click", ".change-billing-user-type", function(event) {
    event.preventDefault();
    changeBillingUserType();
});

$(document).on("change", "#billing-user-type", function(event) {
    changeBillingUserType();
});

function changeBillingUserType() {
    let billingUserType = $("#billing-user-type");
    let billingUserTypeValue = billingUserType.val();
    if (billingUserTypeValue == "company") {
        $("input[name='company_name']")
            .parent()
            .show();
        $("input[name='tax_number']")
            .parent()
            .show();
        $("input[name='company_name']").prop("required", true);
        $("input[name='tax_number']").prop("required", true);
    } else {
        $("input[name='company_name']")
            .parent()
            .hide();
        $("input[name='tax_number']")
            .parent()
            .hide();
        $("input[name='company_name']").prop("required", false);
        $("input[name='tax_number']").prop("required", false);
    }
}
